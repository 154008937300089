/**
* 左边菜单
*/
<template>
  <el-menu default-active="2" :collapse="collapsed" collapse-transition router :default-active="$route.path"
    unique-opened class="el-menu-vertical-demo" background-color="#051f24" text-color="#fff"
    active-text-color="#F0F0F0">
    <div class="logobox">
      <!-- <img class="logoimg" src="../assets/img/logo.png" alt=""> -->
    </div>
    <el-submenu v-for="menu in allmenu" :key="menu.menuid" :index="menu.menuname">
      <template slot="title">
        <i :class="menu.icon"></i>
        <span>{{ menu.menuname }}</span>
      </template>
      <el-menu-item-group>
        <el-menu-item v-for="chmenu in menu.menus" :index="'/' + chmenu.url" :key="chmenu.menuid">
          <!-- <i class="iconfont" :class="chmenu.icon"></i> -->
          <i :class="chmenu.icon"></i>
          <span>{{ chmenu.menuname }}</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
  </el-menu>
</template>
<script>
import { menu } from '../api/userMG'
export default {
  name: 'leftnav',
  data() {
    return {
      collapsed: false,
      allmenu: []
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    let res = {
      success: true,
      systemData: [
        {
          menuid: 10,
          icon: 'el-icon-user-solid',
          menuname: '用户管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 11,
              icon: 'el-icon-tickets',
              menuname: '用户管理',
              hasThird: 'N',
              url: 'tenant/TenantList',
              menus: null
            }
          ]
        },
        {
          menuid: 20,
          icon: 'el-icon-user-solid',
          menuname: '语文课程',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 21,
              icon: 'el-icon-tickets',
              menuname: '词语听写',
              hasThird: 'N',
              url: 'chineseCourse/WordDictation',
              menus: null
            },
            {
              menuid: 21,
              icon: 'el-icon-tickets',
              menuname: '词语审核',
              hasThird: 'N',
              url: 'chineseCourse/WordStatusList',
              menus: null
            }
          ]
        },
        // {
        //   menuid: 20,
        //   icon: 'el-icon-user',
        //   menuname: '用户管理',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 21,
        //       icon: 'el-icon-tickets',
        //       menuname: '用户列表',
        //       hasThird: 'N',
        //       url: 'user/UserList',
        //       menus: null
        //     }
        //   ]
        // },
        // {
        //   menuid: 30,
        //   icon: 'el-icon-s-grid',
        //   menuname: '场景广场',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 31,
        //       icon: 'el-icon-tickets',
        //       menuname: '场景列表',
        //       hasThird: 'N',
        //       url: 'scene/SceneList',
        //       menus: null
        //     }
        //   ]
        // },
        // {
        //   menuid: 71,
        //   icon: 'li-icon-xitongguanli',
        //   menuname: '查看报表',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 72,
        //       icon: 'icon-cus-manage',
        //       menuname: '用户管理',
        //       hasThird: 'N',
        //       url: 'system/user',
        //       menus: null
        //     },
        //     {
        //       menuid: 174,
        //       icon: 'icon-cms-manage',
        //       menuname: '菜单管理',
        //       hasThird: 'N',
        //       url: 'system/Module',
        //       menus: null
        //     },
        //     {
        //       menuid: 73,
        //       icon: 'icon-news-manage',
        //       menuname: '角色管理',
        //       hasThird: 'N',
        //       url: 'system/Role',
        //       menus: null
        //     },
        //     {
        //       menuid: 74,
        //       icon: 'icon-cs-manage',
        //       menuname: '公司管理',
        //       hasThird: 'N',
        //       url: 'system/Dept',
        //       menus: null
        //     },
        //     {
        //       menuid: 75,
        //       icon: 'icon-promotion-manage',
        //       menuname: '系统环境变量',
        //       hasThird: 'N',
        //       url: 'system/Variable',
        //       menus: null
        //     },
        //     {
        //       menuid: 76,
        //       icon: 'icon-cms-manage',
        //       menuname: '权限管理',
        //       hasThird: 'N',
        //       url: 'system/Permission',
        //       menus: null
        //     }
        //   ]
        // },
        // {
        //   menuid: 50,
        //   icon: 'el-icon-setting',
        //   menuname: '系统管理',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 51,
        //       icon: 'el-icon-s-tools',
        //       menuname: '系统配置',
        //       hasThird: 'N',
        //       url: 'system/Setting',
        //       menus: null
        //     }
        //   ]
        // },
        // {
        //   menuid: 70,
        //   icon: 'el-icon-message',
        //   menuname: '问题反馈',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 71,
        //       icon: 'el-icon-chat-dot-square',
        //       menuname: '问题反馈',
        //       hasThird: 'N',
        //       url: 'suggestion/Suggestion',
        //       menus: null
        //     }
        //   ]
        // }
      ],
      tenantData: [
        {
          menuid: 10,
          icon: 'el-icon-folder',
          menuname: '语音合成',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 11,
              icon: 'el-icon-menu',
              menuname: '任务列表',
              hasThird: 'N',
              url: 'task/TaskList',
              menus: null
            },
            {
              menuid: 12,
              icon: 'el-icon-s-custom',
              menuname: '开始任务',
              hasThird: 'N',
              url: 'task/Task',
              menus: null
            }
          ]
        },
        {
          menuid: 20,
          icon: 'el-icon-headset',
          menuname: '音色管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 21,
              icon: 'el-icon-s-fold',
              menuname: '音色列表',
              hasThird: 'N',
              url: 'speaker/SpeakerList',
              menus: null
            }
          ]
        },
        {
          menuid: 30,
          icon: 'el-icon-folder',
          menuname: '项目管理',
          hasThird: null,
          url: null,
          menus: [
            {
              menuid: 31,
              icon: 'el-icon-tickets',
              menuname: '训练记录',
              hasThird: 'N',
              url: 'record/PracticeRecord',
              menus: null
            },

          ]
        },
        // {
        //   menuid: 40,
        //   icon: 'el-icon-notebook-2',
        //   menuname: '报表查询',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 41,
        //       icon: 'el-icon-tickets',
        //       menuname: '用户总训练报表',
        //       hasThird: 'N',
        //       url: 'report/GeneralReport',
        //       menus: null
        //     },
        //     {
        //       menuid: 42,
        //       icon: 'el-icon-document',
        //       menuname: '月训练频率报告',
        //       hasThird: 'N',
        //       url: 'report/MonthFrequencyReport',
        //       menus: null
        //     },
        //     {
        //       menuid: 43,
        //       icon: 'el-icon-document-remove',
        //       menuname: '日训练频率报告',
        //       hasThird: 'N',
        //       url: 'report/DayFrequencyReport',
        //       menus: null
        //     }
        //   ]
        // },
        // {
        //   menuid: 50,
        //   icon: 'el-icon-user',
        //   menuname: '用户管理',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 51,
        //       icon: 'el-icon-tickets',
        //       menuname: '用户列表',
        //       hasThird: 'N',
        //       url: 'user/UserList',
        //       menus: null
        //     },

        //   ]
        // },
        // {
        //   menuid: 60,
        //   icon: 'li-icon-shangchengxitongtubiaozitihuayuanwenjian91',
        //   menuname: '员工管理',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 61,
        //       icon: 'icon-provider-manage',
        //       menuname: '员工列表',
        //       hasThird: 'N',
        //       url: 'machine/MachineConfig',
        //       menus: null
        //     },
        //     {
        //       menuid: 62,
        //       icon: 'icon-provider-manage',
        //       menuname: '支付配置',
        //       hasThird: 'N',
        //       url: 'pay/Config',
        //       menus: null
        //     }
        //   ]
        // },
        // {
        //   menuid: 70,
        //   icon: 'el-icon-message',
        //   menuname: '问题反馈',
        //   hasThird: null,
        //   url: null,
        //   menus: [
        //     {
        //       menuid: 71,
        //       icon: 'el-icon-chat-dot-square',
        //       menuname: '问题反馈',
        //       hasThird: 'N',
        //       url: 'suggestion/Suggestion',
        //       menus: null
        //     }
        //   ]
        // }
      ],
      msg: 'success'
    }
    let role = localStorage.getItem('role')
    if (role === 'tenant') {
      this.allmenu = res.tenantData
    } else {
      this.allmenu = res.systemData

    }

    // menu(localStorage.getItem('logintoken'))
    //   .then(res => {
    //     console.log(JSON.stringify(res))
    //     if (res.success) {
    //       this.allmenu = res.data
    //     } else {
    //       this.$message.error(res.msg)
    //       return false
    //     }
    //   })
    //   .catch(err => {
    //     this.$message.error('菜单加载失败，请稍后再试！')
    //   })
    // 监听
    // this.$root.Bus.('toggle', value => {
    //   this.collapsed = !value
    // })
  }
}
</script>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  min-height: 400px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}

/* 针对当前激活的菜单栏 */
.el-menu-item {
  background-color: #23313b !important;
}

/**激活的菜单项 */
.el-menu-item.is-active {
  background-color: #45535b !important;
  position: relative;
  /* 确保伪元素相对于这个元素定位 */
}

.el-menu-item.is-active::before {
  content: '';
  /* 伪元素需要 content 属性，即使它是空的 */
  position: absolute;
  top: 0;
  /* 根据需要调整位置 */
  left: 0;
  /* 放置在左侧 */
  height: 100%;
  /* 与元素等高 */
  width: 5px;
  /* 竖线的宽度 */
  background-color: #518a99;
  /* 竖线的颜色 */
}

.el-menu-item-group__title {
  padding: 0px;
}

.el-menu-bg {
  background-color: #1f2d3d !important;
}

.el-menu {
  border: none;
}

.logobox {
  height: 40px;
  line-height: 40px;
  color: #9d9d9d;
  font-size: 20px;
  text-align: center;
  padding: 20px 0px;
}

.logoimg {
  height: 40px;
}
</style>
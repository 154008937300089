/**
* 基础菜单 商品管理
*/
<template>
  <div v-loading="loading">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>场景列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="display: flex; justify-content: space-between;">
      <el-button type="success" style="margin-top: 30px;" @click="submit(true)">开始任务</el-button>
      <el-button type="warning" style="margin-top: 30px;" @click="submit(false)">暂存</el-button>

    </div>

    <el-tabs style="margin-top: 20px;height: 180px;" v-model="activeName">
      <el-tab-pane label="选择系统音色" name="third">
        <el-form :inline="true">
          <el-form-item label="选择音色：">
            <el-select v-model="formInline.systemSpeakerId" placeholder="请选择" size="small" clearable>
              <el-option v-for="item in systemSpeakerList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </el-tab-pane>
      <el-tab-pane label="选择已有音色" name="first">
        <el-form :inline="true">
          <el-form-item label="选择音色：">

            <el-select v-model="formInline.speakerId" placeholder="请选择" size="small " clearable>
              <el-option v-for="item in speakerList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </el-tab-pane>
      <el-tab-pane label="自定义音色" name="second">
        <el-form style="height: 80px;" :inline="true">
          <el-form-item label="上传音频：">
            <!-- <el-upload class="upload-demo" action="http://127.0.0.1:5000/upload/audio" :headers="headers" multiple
              :limit="1" :on-success="handleFileSuccess" :on-error="handleFileFail" :on-progress="uploadingHandle"
              :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload> -->

            <el-upload class="upload-demo" action="https://hzsmcs.com/prod-api/upload/audio" :headers="headers" multiple
              :limit="1" :on-success="handleFileSuccess" :on-error="handleFileFail" :on-progress="uploadingHandle"
              :file-list="fileList">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>

          </el-form-item>
          <el-form-item style="margin-left: 30px;" label="音色标签：">
            <el-input style="width: 150px;" size="small" v-model="formInline.speakerLabel"
              placeholder="请输入音色标签"></el-input>
          </el-form-item>

          <!-- <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>



      </el-form-item> -->
        </el-form>
        <el-form style="height: 50px;" :inline="true">

          <el-form-item label="音频提示词：">
            <el-input style="width: 300px;" size="small" v-model="formInline.prompt" placeholder="请输入提示词"></el-input>
          </el-form-item>
          <el-form-item label="">
            <div>注意：音频长度5至10秒，提示词内容需与音频内容完全一致</div>
          </el-form-item>
          <el-form-item style="margin-left: 30px;" label="选择音色性别：">
            <el-select style="width: 100px;" v-model="formInline.gender" placeholder="请选择" size="small" clearable>
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>



      </el-form-item> -->
        </el-form>

      </el-tab-pane>

    </el-tabs>



    <div style="width: 98%;height: 650px;  ">
      <el-input type="textarea" :rows="30" :autosize="false" placeholder="请输入内容" v-model="formInline.content"
        resize="none">
      </el-input>

    </div>

    <div style="width: 98%; display: flex;">
      <el-form :inline="true" :model="formInline">
        <el-form-item label="请输入任务名称：">

          <el-input v-model="formInline.taskName" size="small"></el-input>
        </el-form-item>

      </el-form>
      <el-form style="margin-left: 30px;" :inline="true" :model="formInline">
        <el-form-item label="选择音频语言：">
          <el-select style="width: 120px;" v-model="formInline.isZh" placeholder="请选择" size="small" clearable>
            <el-option v-for="item in isZhList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>

    </div>

  </div>
</template>

<script>
import { getSpeakerListReq, getTTSTaskById, createTTSTaskReq } from '../../api/userMG'
import { getUploadImagePath } from '../../utils/util'
export default {
  data() {
    return {
      speakerList: [],
      systemSpeakerList: [
        { label: '中文 女', value: 1 },
        { label: '中文 男', value: 2 },
        { label: '英文 女', value: 3 },
        { label: '英语 男', value: 4 },
      ],
      genderList: [{
        label: '男',
        value: 2
      },
      {
        label: '女',
        value: 1
      }
      ],
      isZhList: [
        { label: '中文', value: 1 },
        { label: '英语', value: 2 },
      ],
      activeName: 'third',
      loading: false,
      formInline: {
        speakerId: '',
        content: '',
        prompt: '',
        taskName: '',
        filePath: '',
        systemSpeakerId: '',
        gender: '',
        isZh: '',
        speakerLabel: ''
      },
      fileList: [],
      taskId: ''

    }
  },


  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    if (this.$route.query.id) {
      console.log("options", this.$route.query)
      this.taskId = this.$route.query.id
      this.getTask(this.$route.query.id)
    }
    this.getdata()
  },
  mounted() {

  },


  computed: {
    headers() {
      return {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('accessToken')
      }
    }
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata() {
      let res = await getSpeakerListReq()
      console.log(res)
      for (let item of res.data) {
        this.speakerList.push({ label: item.label, value: item.id, gender: item.gender })
      }
      console.log("this.speakerList", this.speakerList)

    },

    async getTask(id) {
      let res = await getTTSTaskById({ id: id })
      console.log("res====>", res)
      if (res.code === 200) {

        if (res.data.spkId = 0) {
          this.formInline.systemSpeakerId = res.data.spkId
          this.activeName = 'third'
        } else if (res.data.spkId = 1) {
          this.activeName = 'first'
          this.formInline.speakerId = res.data.spkId
        } else {
          this.activeName = 'second'
          this.formInline.gender = res.data.gender == 0 ? 2 : 1
        }
        this.formInline.taskName = res.data.taskName
        this.formInline.content = res.data.content
        this.formInline.isZh = res.data.isZh == 0 ? 2 : 1
        this.formInline.prompt = res.data.prompt
        this.formInline.filePath = res.data.promptAudioUrl
      }

    },

    async submit(isTask) {
      console.log("submit", this.formInline, this.activeName)
      let spkCls = 0
      let spkId = ''
      let gender = 0
      if (!this.formInline.taskName) {
        this.$message({
          message: '请输入任务名称！',
          type: 'warning'
        });
        return
      }
      if (!this.formInline.content) {
        this.$message({
          message: '请输入文本内容！',
          type: 'warning'
        });
        return
      }
      if (!this.formInline.isZh) {
        this.$message({
          message: '请选择音频语言！',
          type: 'warning'
        });
      } else {
        if (this.formInline.isZh === 2) {
          this.formInline.isZh = 0
        }
      }

      if (this.activeName === 'first') {//选择已有音色
        if (!this.formInline.speakerId) {
          this.$message({
            message: '请选择音色！',
            type: 'warning'
          });
          return
        }
        spkCls = 1
        spkId = this.formInline.speakerId
        gender = this.speakerList.find(item => item.value === this.formInline.speakerId).gender

      } else if (this.activeName === 'second') {//拷贝音色
        if (!this.formInline.filePath) {
          this.$message({
            message: '请上传音频！',
            type: 'warning'
          });
          return
        }
        if (!this.formInline.prompt) {
          this.$message({
            message: '请填写提示词！',
            type: 'warning'
          });
          return
        }
        if (!this.formInline.gender) {
          this.$message({
            message: '请选择音色性别',
            type: 'warning'
          });
          return
        }
        if (!this.formInline.speakerLabel) {
          this.$message({
            message: '请选择音色标签',
            type: 'warning'
          });
          return
        }
        spkCls = 2
        if (this.formInline.gender === 2) {
          gender = 0
        } else {
          gender = 1
        }
      } else {//系统默认音色
        if (!this.formInline.systemSpeakerId) {
          this.$message({
            message: '请选择系统音色！',
            type: 'warning'
          });
          return
        }
        spkCls = 0
        spkId = this.formInline.systemSpeakerId
        if (spkId === 1 || spkId === 3) {
          gender = 1
        } else {
          gender = 0
        }
      }

      let res = await createTTSTaskReq({
        id: this.taskId,
        taskName: this.formInline.taskName,
        content: this.formInline.content,
        prompt: this.formInline.prompt,
        promptAudioUrl: this.formInline.filePath,
        spkCls: spkCls,
        spkId: spkId,
        completed: isTask ? '' : 2,
        gender: gender,
        isZh: this.formInline.isZh,
        spkLabel: this.formInline.speakerLabel
      })
      if (res.code === 200) {

        this.$router.push({ path: '/task/taskList' })
      }
      console.log("submit", this.formInline)
    },

    uploadingHandle() {
      this.loading = true
    },
    handleFileSuccess(response, file, fileList) {
      this.loading = false
      console.log("handleFileSuccess", response, file, fileList)
      if (response.code === 200) {
        this.$message({
          message: '上传成功！',
          type: 'success'
        });
        this.formInline.filePath = response.data
      } else {
        this.$message({
          message: '上传失败！',
          type: 'error'
        });
        this.fileList = []
      }
    },

    handleFileFail(err, file, fileList) {
      this.loading = false
      console.log("handleFileFail", fileList, this.fileList)
      this.$message({
        message: '上传失败！',
        type: 'error'
      });
      this.fileList = []
    },



  }
}
</script>

<style scoped>
.userRole {
  width: 100%;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.width-320px {
  width: 320px;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
</style>
/**
* 基础菜单 商品管理
*/
<template>
  <div v-loading.fullscreen.lock="loading">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>词语审核</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item label="词语状态：">
        <el-select size="small" v-model="value" placeholder="请选择" @change="statusChangeHandle">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" size="small" style="margin-left: 20px;" @click="checkHandle">审核词语</el-button>
      </el-form-item>


    </el-form>

    <!--列表-->
    <el-table v-show="!loading" size="small" :data="wordList" highlight-current-row border style="width: 70%;">
      <!-- <el-table-column align="center" type="selection" width="60">
      </el-table-column> -->
      <el-table-column sortable type="index" label="序号" align="center" min-width="80">
      </el-table-column>

      <el-table-column prop="word" align="center" label="词语" min-width="100">
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" min-width="100">
        <template slot-scope="scope">
          <div>{{ statusList[scope.row.status] }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="spkLabel" align="center" label="音色标签" min-width="100">
      </el-table-column>
      <el-table-column prop="gender" label="音色性别" align="center" min-width="60">
        <template slot-scope="scope">
          <div>{{ scope.row.gender ? '女' : '男' }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="speed" align="center" label="语速" min-width="40">
      </el-table-column>
      <el-table-column prop="isZh" label="音频语言" align="center" min-width="60">
        <template slot-scope="scope">
          <div>{{ scope.row.isZh ? '中文' : '英语' }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="completed" label="进程" align="center" min-width="60">
        <template slot-scope="scope">
          <div>{{ scope.row.status }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="completed" label="创建时间" align="center" min-width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToHHSS }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="completed" label="试听" align="center" min-width="320">
        <template slot-scope="scope">
          <audio style="width: 300px; height: 30px;" :src="scope.row.audioPath" controls></audio>
        </template>
      </el-table-column> -->
      <el-table-column prop="completed" label="试听" align="center" min-width="320">
        <template slot-scope="scope">
          <audio style="width: 300px; height: 30px;" :src="scope.row.audioUrl" controls></audio>
        </template>
      </el-table-column>

      <el-table-column align="center" label="上传音频" width="160px">
        <template slot-scope="scope">
          <!-- <el-button size="mini" type="warning" @click="dowaloadFile(scope.row)">下载</el-button> -->

          <!-- <el-button size="mini" type="warning" @click="optimizeTask(scope.row)">编辑词汇</el-button> -->

          <el-upload class="upload-demo" :action="uploadAudioPath" :headers="headers" :data="{ id: scope.row.id }"
            accept="audio/*" :show-file-list="false" :on-success="handleAvatarSuccess" :multiple="false">
            <el-button size="small" type="warning">上传音频</el-button>
          </el-upload>

        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" width="160px">
        <template slot-scope="scope">
          <!-- <el-button size="mini" type="warning" @click="dowaloadFile(scope.row)">下载</el-button> -->

          <!-- <el-button size="mini" type="warning" @click="optimizeTask(scope.row)">编辑词汇</el-button> -->

          <el-button type="success" size="mini" @click="checkPassHandle(scope.row.id)">审核通过</el-button>

        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>


    <el-dialog title="词语审核" :visible.sync="checkDialogVisible" width="60%" @click="closeDialog"
      :close-on-click-modal="false">
      <el-divider></el-divider>
      <div
        style="width: 100%;height: 500px;border: 1px solid #DCDFE6;display: flex;flex-direction: column;align-items: center;">
        <div v-show="checkStatus" style="height: 200px;margin-top: 50px;font-size: 100px;font-weight: bold;">{{
          currentWord ? currentWord.word : '' }}
        </div>
        <div style="width: 100%; display: flex;justify-content: space-evenly;margin-top: 100px">
          <el-button v-show="checkStatus" type="success" @click="submitWordStatusHandle(1)">审核通过</el-button>
          <el-button v-show="checkStatus" type="danger" @click="submitWordStatusHandle(2)">审核失败</el-button>

        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="checkStatus" size="small" type="warning" @click="startCheckHandle">暂停审核</el-button>
        <el-button v-else size="small" type="primary" @click="startCheckHandle">开始审核</el-button>

        <el-button size="small" @click="closeDialog">关闭窗口</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import { getWordsByStatusReq, updateWordStatusReq, getGradeConfigReq, getCourseByGradeReq, getChineseWordByCourseReq, addWordsReq, addCourseReq, getTTSTaskListReq, optimizeTaskReq, addSceneReq, updateSceneReq } from '../../api/userMG'
import Pagination from '../../components/Pagination'
import axios from 'axios';
import { getWordAudioPath } from '../../utils/util'

export default {
  data() {
    return {
      options: [{
        value: 0,
        label: '未审核'
      }, {
        value: 1,
        label: '审核通过'
      }, {
        value: 2,
        label: '审核失败'
      }, {
        value: 3,
        label: '全部词语'
      }],
      statusList: ['未审核', '审核通过', '审核失败'],
      checkDialogVisible: false,
      value: 0,
      wordList: [],
      checkStatus: false,
      currentWord: null,
      currentWordIndex: 0,
      courseList: [],
      gradeName: '',
      currentWordList: [],
      currentCourseId: '',
      addHandleDialogVisible: false,
      courseName: '',
      audioElement: null,
      timer: null,
      uploadAudioPath: '',
      loading: false, //是显示加载
      dialogVisible: false, //控制编辑页面显示与隐藏
      optimizeDialogVisible: false,

      showContent: '',
      title: '文本内容',
      currentAudioUrl: '',
      currentTaskId: 0,
      editForm: {
        name: '',
        sceneDescription: '',
        sceneTarget: '',
        example: '',
        imagePath: '',
        classId: '',
        id: '',
      },
      // rules表单验证
      rules: {
        sceneDescription: [
          { required: true, message: '请输入场景描述', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入场景名称', trigger: 'blur' }],
        sceneTarget: [{ required: true, message: '请输入场景目标', trigger: 'blur' }],
        example: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        classId: [{ required: true, message: '请选择分类', trigger: 'blur' }]

      },
      formInline: {
        page: 1,
        pageSize: 10,
        status: 0
        // token: localStorage.getItem('logintoken')
      },
      word_formInline: {
        courseId: '',
        // token: localStorage.getItem('logintoken')
      },
      // 删除部门
      seletedata: {
        ids: '',
        token: localStorage.getItem('logintoken')
      },
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      },
      word_pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      },
      cursorPosition: 0


    }
  },
  // 注册组件
  components: {
    Pagination
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {
    this.init()
  },
  mounted() {
    this.getdata()
    this.uploadAudioPath = getWordAudioPath()

  },

  computed: {
    headers() {
      return {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('accessToken')
      }
    }
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    init() {
      this.getGradeConfig()
      this.getCourseByGrade()
    },
    async getWordsByStatus() {
      let res = await getWordsByStatusReq(this.formInline)
      // let res = await getCourseByGradeReq({ gradeId: 3 })

      console.log("getWordsByStatus", res)
      if (res.code === 200) {
        this.wordList = res.data.records
        this.pageparm.total = res.data.total
      }
    },
    async startCheckHandle() {
      if (this.wordList.length <= 0) {
        this.$message.warning('暂无待审核词语')
        return
      }
      this.checkStatus = !this.checkStatus
      if (this.checkStatus) {
        await this.getWordsByStatus()
        this.currentWordIndex = 0
        if (this.wordList.length > 0) {
          this.currentWord = this.wordList[this.currentWordIndex]
          this.playWordAudio()
        }
      } else {
        this.timer = null
      }
    },
    playWordAudio() {
      let _this = this
      if (!this.timer) {
        this.timer = setTimeout(() => {
          if (_this.checkStatus) {
            _this.audioElement = new Audio(_this.currentWord.audioUrl)
            _this.audioElement.play()
            _this.timer = null
            _this.playWordAudio()
          } else {
            _this.timer = null
          }
        }, 3000); // 2000 毫秒即 2 秒
      }

    },
    checkPassHandle(id) {
      this.$confirm('审核通过, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await updateWordStatusReq({ id: id, status: 1 })
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          });
        }
        this.getWordsByStatus()

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async submitWordStatusHandle(status) {
      if (this.currentWord) {
        let res = await updateWordStatusReq({ id: this.currentWord.id, status: status })
        console.log("submitWordStatusHandle", res, this.currentWord)
        if (res.code === 200) {
          if (this.currentWordIndex < this.wordList.length - 1) {
            this.currentWordIndex++
            this.currentWord = this.wordList[this.currentWordIndex]
            this.playWordAudio()
          } else {
            await this.getWordsByStatus()
            this.currentWordIndex = 0
            if (this.wordList.length > 0) {
              this.currentWord = this.wordList[this.currentWordIndex]
              this.playWordAudio()
            } else {
              this.checkStatus = false
              this.currentWord = null
            }
          }
        }
      }

    },

    checkHandle() {
      if (this.wordList.length <= 0) {
        this.$message.warning('暂无待审核词语')
        return
      }
      this.checkDialogVisible = true
    },

    async getChineseWordByCourse(id) {
      let res = await getChineseWordByCourseReq({ courseId: id })
      console.log("getChineseWordByCourse", res)
      if (res.code === 200) {
        this.currentWordList = res.data
      }
    },
    toCreateCourse() {
      console.log("toCreateCourse", this.value, this.courseName)
      if (!this.value || !this.courseName) {
        this.$message.error('请输入年级和课程名称')
        return
      }
      this.addCourse(this.value, this.courseName)
    },

    addCourseHandle() {
      this.addHandleDialogVisible = true

    },
    async addCourse(id, name) {

      let res = await addCourseReq({ gradeId: id, courseName: name })
      if (res.code === 200) {
        this.$message.success('添加成功')
        this.addHandleDialogVisible = false
        this.getCourseByGrade(id)
      }
    },
    async getGradeConfig() {
      let res = await getGradeConfigReq()
      console.log("getGradeConfig", res)
      if (res.code === 200) {
        this.gradeList = res.data
        for (let item of this.gradeList) {
          if (item.semester) {
            item.label = item.label + '  下'
          } else {
            item.label = item.label + '  上'
          }
          item.value = item.id
        }
      }
      console.log("gradeList", this.gradeList)
    },

    statusChangeHandle(item) {
      console.log("111", item)
      // this.gradeName = this.gradeList.find(grade => grade.id === item).label
      this.formInline.status = item
      this.getWordsByStatus()
      console.log("gradeName", this.gradeName)
    },
    deleteImage() {
      console.log("点击删除图片")
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.editForm.imagePath = ''
      }).catch(() => {

      });
    },
    handleAvatarSuccess(response) {
      if (response.code === 200) {
        this.editForm.imagePath = response.data
        this.$message({
          type: 'success',
          message: '音频上传成功！'
        })

        this.getWordsByStatus()
      }
    },

    beforUploadHanle(item) {
      console.log('beforUploadHanle', item);
    },

    // 获取公司列表
    async getdata() {
      //this.search()
      this.getWordsByStatus()
    },
    async dowaloadFile(item) {
      console.log(item)
      try {
        const response = await axios({
          url: item.audioPath,
          method: 'GET',
          responseType: 'blob' // 告诉axios我们期望服务器返回一个Blob对象  
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        //"https://img.tukuppt.com/newpreview_music/01/56/89/63774647a698882.mp3"
        let filename = item.audioPath.split('/').pop();
        link.setAttribute('download', filename); // 设置下载文件名  
        document.body.appendChild(link);
        link.click();
        // 释放URL对象  
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    toTaskHandle(item) {
      this.$router.push({
        path: '/task/Task?id=' + item.id
      })
    },

    async toCreateTask() {
      if (this.showContent) {
        let words = this.showContent.split(' ')
        let wordsWihtoutPause = []
        for (let word of words) {
          console.log(word)
          if (word && word != ' ') {
            wordsWihtoutPause.push(word)
          }
        }
        let str = wordsWihtoutPause.join(',')
        if (wordsWihtoutPause && wordsWihtoutPause.length > 0 && str && str.length > 0) {
          let res = await addWordsReq({ courseId: this.currentCourseId, words: str })
          console.log("addWordsReq", res)
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '添加成功'
            })

            this.optimizeDialogVisible = false
            this.showContent = ""
            this.currentCourseId = ""
          }
        }
        console.log(wordsWihtoutPause, str)
      }
    },
    addPause() {
      const inputComponent = this.$refs.inputRef;

      if (inputComponent) {
        this.cursorPosition = inputComponent.$refs.textarea.selectionStart;
        console.log(`Cursor position: ${this.cursorPosition}`);
        let textBeforeCursor = this.showContent.substring(0, this.cursorPosition);
        let textAfterCursor = this.showContent.substring(this.cursorPosition);

        // 在光标位置插入新文本
        this.showContent = textBeforeCursor + '<pause>' + textAfterCursor;
      }
    },
    optimizeTask(item) {
      this.optimizeDialogVisible = true
      this.showContent = ""
      this.currentCourseId = item.id
      console.log(item)
    },
    showContentHandle(item) {
      console.log("item", item)
      this.word_formInline.courseId = item.id
      this.dialogVisible = true
      this.getChineseWordByCourse(item.id)
    },

    playAudio(item) {
      console.log("playaudio", item)
      this.audioElement = new Audio(item.audioUrl)
      this.audioElement.play()
    },

    dateChange() {
      if (this.formInline.date) {
        this.formInline.beginDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
        console.log(this.formInline.beginDate)
        console.log(this.formInline.endDate)

      } else {
        this.formInline.beginDate = ''
        this.formInline.endDate = ''
      }
    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.getdata(this.formInline)
    },
    word_callFather(parm) {
      this.word_formInline.page = parm.currentPage
      this.word_formInline.limit = parm.pageSize
      this.getChineseWordByCourse()
    },
    // 搜索事件
    async search() {
      let res = await getTTSTaskListReq(this.formInline)
      if (res.code === 200) {
        this.listData = res.data.records
        for (let item of this.listData) {
          if (item.completed === 1) {
            item.status = '已完成'
          } else if (item.completed === 2) {
            item.status = '暂存'
          } else {
            item.status = '未完成'
          }
        }
      }
      console.log("search", res)
      // this.getdata(this.formInline)
      // 分页赋值
      this.pageparm.currentPage = this.formInline.page
      this.pageparm.pageSize = this.formInline.pageSize
      this.pageparm.total = res.data.total
    },

    reset() {
      this.formInline = {
        page: 1,
        pageSize: 10,
        id: '',
        beginDate: '',
        endDate: ''
      }
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      console.log("handleEdit", index, row)
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '编辑'
        this.editForm.id = row.id
        this.editForm.name = row.name
        this.editForm.imagePath = row.imagePath
        this.editForm.sceneDescription = row.sceneDescription
        this.editForm.sceneTarget = row.sceneTarget
        this.editForm.example = row.example
        this.editForm.classId = row.classId
      } else {
        this.title = '添加'
      }
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        console.log("editForm **", this.editForm, valid)
        if (!this.editForm.imagePath) {
          this.$message.error('请上传图片！')
          return
        }


        if (valid) {
          if (this.editForm.id) {
            //编辑修改
            let res = await updateSceneReq(this.editForm)
            this.editFormVisible = false
            this.loading = false
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: this.editForm.id ? '更新成功' : '添加成功！'
              })
            } else {
              this.$message({
                type: 'error',
                message: '更新失败！' + res.message
              })
            }
          } else {
            //添加
            let res = await addSceneReq(this.editForm)
            this.editFormVisible = false
            this.loading = false
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: this.editForm.id ? '更新成功' : '添加成功！'
              })
            } else {
              this.$message({
                type: 'error',
                message: '添加失败！' + res.message
              })
            }

          }

          this.search()


        } else {
          return false
        }
      })
    },



    // 关闭编辑、增加弹出框
    closeDialog() {
      this.checkDialogVisible = false
      this.checkStatus = false
      this.timer = null
    },

  },

}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.el-tag:hover {
  cursor: pointer;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.width-320px {
  width: 320px;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
</style>
/**
* 基础菜单 商品管理
*/
<template>
  <div v-loading.fullscreen.lock="loading">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>任务列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="formInline" class="user-search">
      <el-form-item label="搜索：">
        <el-date-picker v-model="formInline.date" @change="dateChange" size="small" type="daterange" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']">
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
        <el-button size="small" type="info" icon="el-icon-refresh" @click="reset">重置</el-button>



      </el-form-item>
    </el-form>
    <!--列表-->
    <el-table v-show="!loading" size="small" :data="listData" highlight-current-row border style="width: 98%;">
      <!-- <el-table-column align="center" type="selection" width="60">
      </el-table-column> -->
      <el-table-column sortable type="index" label="序号" align="center" min-width="80">
      </el-table-column>

      <el-table-column prop="taskName" align="center" label="任务名称" min-width="200">
      </el-table-column>
      <el-table-column prop="spkCls" label="音色种类" align="center" min-width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.spkCls ? '自定义音色' : '系统音色' }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="spkLabel" align="center" label="音色标签" min-width="100">
      </el-table-column>
      <el-table-column prop="gender" label="音色性别" align="center" min-width="60">
        <template slot-scope="scope">
          <div>{{ scope.row.gender ? '女' : '男' }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="speed" align="center" label="语速" min-width="40">
      </el-table-column>
      <el-table-column prop="isZh" label="音频语言" align="center" min-width="60">
        <template slot-scope="scope">
          <div>{{ scope.row.isZh ? '中文' : '英语' }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="completed" label="进程" align="center" min-width="60">
        <template slot-scope="scope">
          <div>{{ scope.row.status }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="completed" label="创建时间" align="center" min-width="100">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime | timestampToHHSS }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="completed" label="试听" align="center" min-width="320">
        <template slot-scope="scope">
          <audio style="width: 300px; height: 30px;" :src="scope.row.audioPath" controls></audio>
        </template>
      </el-table-column>


      <el-table-column align="center" label="操作" width="260px">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="dowaloadFile(scope.row)">下载</el-button>
          <el-button size="mini" type="success" @click="showContentHandle(scope.row)">查看文本</el-button>
          <el-button v-if="scope.row.completed === 2" size="mini" type="primary"
            @click="toTaskHandle(scope.row)">继续任务</el-button>
          <el-button v-else size="mini" type="danger" @click="optimizeTask(scope.row)">优化任务</el-button>

        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Pagination v-show="!loading" v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <!-- 编辑界面 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="60%" @click="closeDialog"
      :close-on-click-modal="false">
      <div style="width: 100%;display: flex;justify-content: center;"><audio style="width: 400px; height: 30px;"
          :src="currentAudioUrl" controls></audio>
      </div>
      <el-divider></el-divider>
      <div style="width: 100%;min-height: 600px; display: flex;flex-direction: column;">
        {{ showContent }}

      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeDialog">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="任务优化" :visible.sync="optimizeDialogVisible" width="60%" @click="closeDialog"
      :close-on-click-modal="false">
      <div style="width: 100%;display: flex;justify-content: center;"><audio style="width: 400px; height: 30px;"
          :src="currentAudioUrl" controls></audio>
      </div>
      <div style="width: 100%;height: 50px;margin-top: 10px;">
        <el-tag type="warning" @click="addPause">停顿0.5秒</el-tag>
      </div>
      <el-divider></el-divider>
      <el-input ref="inputRef" type="textarea" :rows="30" :autosize="false" placeholder="请输入内容" v-model="showContent"
        resize="none">
      </el-input>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="warning" @click="toCreateTask">发布任务</el-button>

        <el-button size="small" @click="closeDialog">关闭窗口</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getTTSTaskListReq, optimizeTaskReq, addSceneReq, updateSceneReq } from '../../api/userMG'
import Pagination from '../../components/Pagination'
import axios from 'axios';
export default {
  data() {
    return {
      loading: false, //是显示加载
      dialogVisible: false, //控制编辑页面显示与隐藏
      optimizeDialogVisible: false,

      showContent: '',
      title: '文本内容',
      currentAudioUrl: '',
      currentTaskId: 0,
      editForm: {
        name: '',
        sceneDescription: '',
        sceneTarget: '',
        example: '',
        imagePath: '',
        classId: '',
        id: '',
      },
      // rules表单验证
      rules: {
        sceneDescription: [
          { required: true, message: '请输入场景描述', trigger: 'blur' }
        ],
        name: [{ required: true, message: '请输入场景名称', trigger: 'blur' }],
        sceneTarget: [{ required: true, message: '请输入场景目标', trigger: 'blur' }],
        example: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        classId: [{ required: true, message: '请选择分类', trigger: 'blur' }]

      },
      formInline: {
        page: 1,
        pageSize: 10,
        id: '',
        beginDate: '',
        endDate: ''
        // token: localStorage.getItem('logintoken')
      },
      // 删除部门
      seletedata: {
        ids: '',
        token: localStorage.getItem('logintoken')
      },
      listData: [], //用户数据
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 10
      },
      cursorPosition: 0


    }
  },
  // 注册组件
  components: {
    Pagination
  },

  /**
   * 数据发生改变
   */

  /**
   * 创建完毕
   */
  created() {

  },
  mounted() {
    this.getdata()
  },

  computed: {
    headers() {
      return {
        // 'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('accessToken')
      }
    }
  },

  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    // 获取公司列表
    async getdata() {
      this.search()
    },
    async dowaloadFile(item) {
      console.log(item)
      try {
        const response = await axios({
          url: item.audioPath,
          method: 'GET',
          responseType: 'blob' // 告诉axios我们期望服务器返回一个Blob对象  
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        //"https://img.tukuppt.com/newpreview_music/01/56/89/63774647a698882.mp3"
        let filename = item.audioPath.split('/').pop();
        link.setAttribute('download', filename); // 设置下载文件名  
        document.body.appendChild(link);
        link.click();
        // 释放URL对象  
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    },
    toTaskHandle(item) {
      this.$router.push({
        path: '/task/Task?id=' + item.id
      })
    },
    async toCreateTask() {
      let res = await optimizeTaskReq({ taskId: this.currentTaskId, content: this.showContent })
      console.log("toCreateTask", res)
      if (res.code === 200) {
        this.$router.push({
          path: '/task/Task?id=' + res.data
        })
      }
    },
    addPause() {
      const inputComponent = this.$refs.inputRef;

      if (inputComponent) {
        this.cursorPosition = inputComponent.$refs.textarea.selectionStart;
        console.log(`Cursor position: ${this.cursorPosition}`);
        let textBeforeCursor = this.showContent.substring(0, this.cursorPosition);
        let textAfterCursor = this.showContent.substring(this.cursorPosition);

        // 在光标位置插入新文本
        this.showContent = textBeforeCursor + '<pause>' + textAfterCursor;
      }
    },
    optimizeTask(item) {
      this.optimizeDialogVisible = true
      this.currentTaskId = item.id
      this.showContent = item.content
      this.currentAudioUrl = item.audioPath
    },
    showContentHandle(item) {
      this.dialogVisible = true
      this.showContent = item.content
      this.currentAudioUrl = item.audioPath
    },

    playAudio(item) {
      console.log("playaudio", item)
    },

    dateChange() {
      if (this.formInline.date) {
        this.formInline.beginDate = this.formInline.date[0]
        this.formInline.endDate = this.formInline.date[1]
        console.log(this.formInline.beginDate)
        console.log(this.formInline.endDate)

      } else {
        this.formInline.beginDate = ''
        this.formInline.endDate = ''
      }
    },
    // 分页插件事件
    callFather(parm) {
      this.formInline.page = parm.currentPage
      this.formInline.limit = parm.pageSize
      this.getdata(this.formInline)
    },
    // 搜索事件
    async search() {
      let res = await getTTSTaskListReq(this.formInline)
      if (res.code === 200) {
        this.listData = res.data.records
        for (let item of this.listData) {
          if (item.completed === 1) {
            item.status = '已完成'
          } else if (item.completed === 2) {
            item.status = '暂存'
          } else {
            item.status = '未完成'
          }
        }
      }
      console.log("search", res)
      // this.getdata(this.formInline)
      // 分页赋值
      this.pageparm.currentPage = this.formInline.page
      this.pageparm.pageSize = this.formInline.pageSize
      this.pageparm.total = res.data.total
    },

    reset() {
      this.formInline = {
        page: 1,
        pageSize: 10,
        id: '',
        beginDate: '',
        endDate: ''
      }
    },
    //显示编辑界面
    handleEdit: function (index, row) {
      console.log("handleEdit", index, row)
      this.editFormVisible = true
      if (row != undefined && row != 'undefined') {
        this.title = '编辑'
        this.editForm.id = row.id
        this.editForm.name = row.name
        this.editForm.imagePath = row.imagePath
        this.editForm.sceneDescription = row.sceneDescription
        this.editForm.sceneTarget = row.sceneTarget
        this.editForm.example = row.example
        this.editForm.classId = row.classId
      } else {
        this.title = '添加'
      }
    },
    // 编辑、增加页面保存方法
    submitForm(editData) {
      this.$refs[editData].validate(async valid => {
        console.log("editForm **", this.editForm, valid)
        if (!this.editForm.imagePath) {
          this.$message.error('请上传图片！')
          return
        }


        if (valid) {
          if (this.editForm.id) {
            //编辑修改
            let res = await updateSceneReq(this.editForm)
            this.editFormVisible = false
            this.loading = false
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: this.editForm.id ? '更新成功' : '添加成功！'
              })
            } else {
              this.$message({
                type: 'error',
                message: '更新失败！' + res.message
              })
            }
          } else {
            //添加
            let res = await addSceneReq(this.editForm)
            this.editFormVisible = false
            this.loading = false
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: this.editForm.id ? '更新成功' : '添加成功！'
              })
            } else {
              this.$message({
                type: 'error',
                message: '添加失败！' + res.message
              })
            }

          }

          this.search()


        } else {
          return false
        }
      })
    },



    // 关闭编辑、增加弹出框
    closeDialog() {
      this.dialogVisible = false
      this.showContent = ''
      this.currentAudioUrl = ''
      this.optimizeDialogVisible = false
    },

  },

}
</script>

<style scoped>
.user-search {
  margin-top: 20px;
}

.userRole {
  width: 100%;
}

.el-tag:hover {
  cursor: pointer;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.avatar-container {
  display: flex;
  line-height: 80px;
  margin-bottom: 10px;
}

.avatar-uploader-border {
  border: 1px dashed #8c939d;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar {
  width: 80px;
  height: 80px;
  display: block;
}

.width-320px {
  width: 320px;
}

.avatar-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  width: 80px;
  height: 80px;
  overflow: hidden;
}
</style>
import axios from "axios";
import { getRefreshToken, setAccessToken, setRefreshToken } from "./token";
import router from '../router'
const baseURL = process.env.VUE_APP_BASE_URL


let isRefreshing = false; // 是否正在刷新token

let callbacks = []; // 失效后同时发送请求的容器 -- 缓存接口

// 刷新 token 后, 将缓存的接口重新请求一次
function onAccessTokenFetched(newToken) {
    callbacks.forEach(callback => {
        callback(newToken);
    });
    // 清空缓存接口
    callbacks = [];
}


// 添加缓存接口
function addCallbacks(callback) {
    callbacks.push(callback);
}

// 刷新token
async function refreshToken() {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        //  Authorization: localStorage.getItem('accessToken')
    };
    let params = {
        refreshToken: getRefreshToken()
    };
    // 如果params是对象，则需要转换为x-www-form-urlencoded格式
    if (typeof params === 'object') {
        headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        params = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
    } else {
        // 如果params是字符串，则保持原样
        headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    let refreshToken = getRefreshToken();
    console.log("进入token刷新函数")
    console.log("refreshToken", refreshToken)
    return service({
        method: 'post',
        url: "/token",
        headers: headers,
        data: params,
        // 注意：不要在这里设置transformRequest，因为它已经在拦截器中处理了
    }).then(res => {
        return Promise.resolve(res);
    });
}


// create an axios instance
const service = axios.create({
    baseURL: baseURL,//'http://114.55.224.133:5000/', // url = base url + request url
    //  withCredentials: true, // send cookies when cross-domain requests
    timeout: 50000 // request timeout
});

// request interceptor
service.interceptors.request.use(
    config => {
        console.log("请求拦截器***", config)
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers["Authorization"] = accessToken;

        }
        return config;
    },
    error => {
        // do something with request error
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    response => {
        console.log("响应拦截器***", response)
        if (response.data.code === 200) {
            return response;
        }

        if (response.data && response.data.code === 503) {
            if (response.data.data && response.data.data.isRefreshToken) {
                //返回登录页
                localStorage.setItem("userInfo", '')
                localStorage.setItem("accessToken", '')
                localStorage.setItem("refreshToken", '')

                router.push({ path: '/login' })
                console.log("token刷新失败，返回登录页")
                return
            } else {
                const config = response.config;
                const retryOriginalRequest = new Promise(resolve => {
                    addCallbacks(newToken => {
                        // console.log(newToken)
                        // console.log(config)
                        // 表示用新的token去替换掉原来的token
                        config.headers["Authorization"] = newToken;
                        // 替换掉url -- 因为baseURL会扩展请求url
                        // config.url = config.url.replace(apiUrl, '')
                        resolve(service(config)); // 调用resolve请求队列里面接口
                    });

                    // 无感刷新Token
                    if (!isRefreshing) {
                        isRefreshing = true;
                        refreshToken()
                            .then(res => {
                                console.log('refreshToken res===', res)
                                // 用rftoken获取新的token

                                const newToken = res.data.data.accessToken
                                const newRefreshToken = res.data.data.refreshToken
                                setAccessToken(newToken)
                                setRefreshToken(newRefreshToken)
                                // setToken()
                                // setRefreshToken()
                                onAccessTokenFetched(newToken);
                            })
                            .catch(res => {

                            })
                            .finally(() => {
                                isRefreshing = false;
                            });
                    }
                });
                // 将token过期期间请求的接口包装成promise返回，等待刷新token后重新请求
                return retryOriginalRequest;
            }
        } else {
            return response
        }
    },
    error => {


        return Promise.reject(error);
    }
);

export default service;
